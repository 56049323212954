<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-12 col-lg-12">
                
                <div class="card">
                    <div class="card-body">
                        <p class="card-title">Production Performa</p>
                        <div id="chartContainer1" style="height: 300px; width: 100%;"></div>
                    </div>
                </div>

            </div>

            <div class="col-12 col-lg-6">
                
                <div class="card">
                    <div class="card-body">
                        <p class="card-title">Production Performance</p>
                        <div id="chartContainer2" style="height: 300px; width: 100%;"></div>
                    </div>
                </div>

            </div>

            <div class="col-12 col-lg-6">
                
                <div class="card">
                    <div class="card-body">
                        <p class="card-title">Production Performance</p>
                        <div id="chartContainer3" style="height: 300px; width: 100%;"></div>
                    </div>
                </div>

            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                            
                    <div id="chartContainer4" style="height: 300px; width: 100%;"></div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'Dashboard',
                disabled: false,
                href: '#',
                },
                {
                text: 'Analytics Dashboard',
                disabled: true,
                href: '#',
                },
            ],
            
        }
    },
    async mounted(){
        
        this.getDashboardItems()


    },
    methods:{
        
        async getDashboardItems(){

            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/AnalyticsDashboard/getDashboardOrderChart1`, { 
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    
                    this.renderChart1(res.data.curr_year, res.data.prev_year, res.data.arr_list2, res.data.arr_list1)

            })

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/AnalyticsDashboard/getDashboardOrderChart2`, { 
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    
                    this.renderChart2(res.data.curr_year, res.data.curr_month, res.data.arr_list)

            })

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/AnalyticsDashboard/getDashboardOrderChart3`, { 
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    
                    this.renderChart3(res.data.curr_year, res.data.sub_month, res.data.arr_list)

            })

            this.$store.dispatch('setOverlay', false)

        },

        renderChart1(curr_year, prev_year, data1, data2){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                title: {
                    text: prev_year + ' - ' + curr_year,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Tons",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    fontSize: 11,
                    itemMaxWidth: 150,
                    itemWrap: true ,
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.getDetails1,
                        showInLegend: true,
                        name: "Sum.Result("+prev_year+")",
                        indexLabel: "{y}",
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "vertical",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        indexLabelFontColor: "#fff",
                        color: "#0e9aa7",
                        dataPoints: data1
                    },
                    {
                        type: "column",
                        click: this.getDetails2,
                        showInLegend: true,
                        name: "Sum.Result("+curr_year+")",
                        indexLabel: "{y}",
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "vertical",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        indexLabelFontColor: "#fff",
                        color: "#c70039",
                        dataPoints: data2
                    }
                ]
            })

            chart1.render()

        },

        renderChart2(curr_year, curr_month, data){

            var chart2 = new CanvasJS.Chart("chartContainer2", {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: false,
                title: {
                    text: curr_month + ', ' + curr_year,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} {y} #percent%",
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    dataPoints: data
                }]
            });

            chart2.render()

        },

        renderChart3(curr_year, sub_month, data){

            var chart3 = new CanvasJS.Chart("chartContainer3", {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: false,
                title: {
                    text: sub_month + ', ' + curr_year,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} {y} #percent%",
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    dataPoints: data
                }]
            });

            chart3.render()

        },

        renderChart4(year, month, data){

            var chart4 = new CanvasJS.Chart("chartContainer4", {
                animationEnabled: true,
                theme: "light2",
                title: {
                    text: month + ', ' + year,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    }
                },
                axisY: {
                    scaleBreaks: {
                        autoCalculate: true,
                        type: "wavy"
                    },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Tons",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    content: "{label}: {y}"
                },
                legend: {
                    fontSize: 11,
                    itemMaxWidth: 150,
                    itemWrap: true ,
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelOrientation: "horizontal",
                        indexLabelFontSize: 14,
                        indexLabelFontFamily: "calibri",
                        indexLabelFontWeight: "bold",
                        dataPoints: data
                    }
                ]
            });

            chart4.render()

        },

        async getDetails1(e){

            // console.log(e.dataPoint.x + 1)
            console.log('A')

            $('#detailModal').modal('show')

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/AnalyticsDashboard/getDetails1`, { 

                month: e.dataPoint.x + 1

            },
            {
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            }
            )
            .then(res => {

                this.renderChart4(res.data.year, res.data.month, res.data.arr_list)

            });

        },

        async getDetails2(e){

            // console.log(e.dataPoint.x + 1)
            console.log('B')

            $('#detailModal').modal('show')

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/AnalyticsDashboard/getDetails2`, { 

                month: e.dataPoint.x + 1

            },
            {
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            }
            )
            .then(res => {

                this.renderChart4(res.data.year, res.data.month, res.data.arr_list)

            });

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },

        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
            e.dataPoint.y = e.dataPoint.actualYValue;
            e.dataPoint.actualYValue = null;
            e.dataPoint.indexLabelFontSize = null;
            e.dataPoint.indexLabelLineThickness = null;
            e.dataPoint.legendMarkerType = "circle";
        } 
        else {
            e.dataPoint.actualYValue = e.dataPoint.y;
            e.dataPoint.y = 0;
            e.dataPoint.indexLabelFontSize = 0;
            e.dataPoint.indexLabelLineThickness = 0; 
            e.dataPoint.legendMarkerType = "cross";
        }
            e.chart.render();
        }

    }
    
}
</script>

<style scoped>

    .flex-container {
        display: flex;
        justify-content: space-between;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .container{
        margin-bottom: 60px !important;
    }

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>